<template lang="html">
  <div>
    <VueHtml2pdf
      :show-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1100"
      :filename="fileName"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="letter"
      pdf-orientation="portrait"
      pdf-content-width="850px"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf">
      <div class="p-5 text-black" slot="pdf-content">
        <div class="d-flex align-items-center justify-content-center mb-5">
          <div class="col-3"></div>
          <div class="col-6 me-5 text-center">
            <h3>Formato de Filiación</h3>
          </div>
          <div class="col-3 text-center">
            <img
              style="width: 5rem; height: 5rem"
              :src="require('@/assets/images/filiation_format.svg')" />
          </div>
        </div>
        <div class="d-flex justify-content-around mb-3">
          <div class="d-flex col-4 align-items-center justify-content-start">
            <div class="col-2 pb-1 text-start me-2">
              <span class="fw-bold">Fecha </span>
            </div>
            <div class="col-6">
              <input
                class="form-control underLine text-center"
                :value="pdfData.last_appointment" />
            </div>
          </div>
          <div class="d-flex col-3 align-items-center">
            <div class="col-2 pb-1 me-2">
              <span class="fw-bold">NEC </span>
            </div>
            <div class="col-9">
              <input
                class="form-control underLine text-center"
                :value="pdfData.nec" />
            </div>
          </div>
          <div class="d-flex col-5 align-items-center justify-content-end pe-5">
            <div class="col-4 pb-1">
              <span class="fw-bold">No. Filiación </span>
            </div>
            <div class="col-6">
              <input
                class="form-control underLine text-center"
                :value="pdfData.filiation_num" />
            </div>
          </div>
        </div>
        <!-- DATOS GENERALES -->
        <div class="d-flex justify-content-center pe-5 mb-3">
          <div class="col-12 text-center text-white skyLightBanner fw-bold">
            Datos Generales
          </div>
        </div>
        <div class="row justify-content-between mb-4 pe-5">
          <div class="d-flex col-6 align-items-center">
            <div class="col-3 pb-1">
              <span class="fw-bold">Nombre(s) </span>
            </div>
            <div class="col-9">
              <input class="form-control underLine" :value="pdfData.name" />
            </div>
          </div>
          <div class="d-flex col-6 align-items-center">
            <div class="col-3 pb-1">
              <span class="fw-bold">Apellido(s) </span>
            </div>
            <div class="col-9">
              <input
                class="form-control underLine"
                :value="pdfData.last_name" />
            </div>
          </div>
        </div>
        <div class="row justify-content-between mb-4 pe-5">
          <div class="d-flex col-4 align-items-center">
            <div class="col-8 pb-1">
              <span class="fw-bold">Fecha de nacimiento </span>
            </div>
            <div class="col-4">
              <input
                class="form-control underLine text-center"
                :value="pdfData.date_birth" />
            </div>
          </div>
          <div class="d-flex col align-items-center">
            <div class="col-6 pb-1">
              <span class="fw-bold">Edad </span>
            </div>
            <div class="col-6">
              <input
                class="form-control underLine text-center"
                :value="pdfData.age" />
            </div>
          </div>
          <div class="d-flex col align-items-center">
            <div class="col-6 pb-1">
              <span class="fw-bold">Sexo </span>
            </div>
            <div class="col-6">
              <input
                class="form-control underLine text-center"
                :value="formatSex(pdfData.sex)" />
            </div>
          </div>
          <div class="d-flex col-5 align-items-center">
            <div class="col-3 pb-1">
              <span class="fw-bold">Tipo ID </span>
            </div>
            <div class="col-9">
              <input
                class="form-control underLine text-center"
                :value="formatID(pdfData.identification)" />
            </div>
          </div>
        </div>
        <div class="row justify-content-start mb-4 pe-5">
          <div class="d-flex col-12 align-items-center">
            <div class="col-2 pb-1">
              <span class="fw-bold">Ocupación </span>
            </div>
            <div class="col-10">
              <input
                class="form-control underLine"
                :value="dynamicOcupationPatient" />
            </div>
          </div>
        </div>
        <div class="row justify-content-start mb-4 pe-5">
          <div class="d-flex col-7 align-items-center">
            <div class="col-7 pb-1">
              <span class="fw-bold">¿Ha Tenido tratamientos previos? </span>
            </div>
            <div class="col-4">
              <input
                class="form-control underLine"
                :value="pdfData.previous_treatment ? 'Yes' : 'No'" />
            </div>
          </div>
        </div>
        <!-- DATOS DE PAREJA -->
        <div class="d-flex justify-content-center pe-5 mb-3">
          <div class="col-12 text-center text-white skyLightBanner fw-bold">
            Datos Pareja
          </div>
        </div>
        <div class="row justify-content-between mb-4 pe-5">
          <div class="d-flex col-6 align-items-center">
            <div class="col-3 pb-1">
              <span class="fw-bold">Nombre(s) </span>
            </div>
            <div class="col-9">
              <input
                class="form-control underLine"
                :value="pdfData.partner_name" />
            </div>
          </div>
          <div class="d-flex col-6 align-items-center">
            <div class="col-3 pb-1">
              <span class="fw-bold">Apellido(s) </span>
            </div>
            <div class="col-9">
              <input
                class="form-control underLine"
                :value="pdfData.partner_last_name" />
            </div>
          </div>
        </div>
        <div class="row justify-content-between mb-4 pe-5">
          <div class="d-flex col-4 align-items-center">
            <div class="col-8 pb-1">
              <span class="fw-bold">Fecha de nacimiento </span>
            </div>
            <div class="col-4">
              <input
                class="form-control underLine text-center"
                :value="pdfData.partner_date_birth" />
            </div>
          </div>
          <div class="d-flex col align-items-center">
            <div class="col-6 pb-1">
              <span class="fw-bold">Edad </span>
            </div>
            <div class="col-6">
              <input
                class="form-control underLine text-center"
                :value="pdfData.partner_age" />
            </div>
          </div>
          <div class="d-flex col align-items-center">
            <div class="col-6 pb-1">
              <span class="fw-bold">Sexo </span>
            </div>
            <div class="col-6">
              <input
                class="form-control underLine text-center"
                :value="formatSex(pdfData.partner_sex)" />
            </div>
          </div>
          <div class="d-flex col-5 align-items-center">
            <div class="col-3 pb-1">
              <span class="fw-bold">Tipo ID </span>
            </div>
            <div class="col-9">
              <input
                class="form-control underLine text-center"
                :value="formatID(pdfData.partner_identification)" />
            </div>
          </div>
        </div>
        <div class="row justify-content-start mb-4 pe-5">
          <div class="d-flex col-12 align-items-center">
            <div class="col-2 pb-1">
              <span class="fw-bold">Ocupación </span>
            </div>
            <div class="col-10">
              <input
                class="form-control underLine"
                :value="dynamicOcupationCouple" />
            </div>
          </div>
        </div>
        <!-- DIRECCIÓN -->
        <div class="d-flex justify-content-center pe-5 mb-3">
          <div class="col-12 text-center text-white skyLightBanner fw-bold">
            Dirección
          </div>
        </div>
        <div class="row justify-content-between mb-4 pe-5">
          <div class="d-flex col-3 align-items-center">
            <div class="col-3 pb-1">
              <span class="fw-bold">C.P. </span>
            </div>
            <div class="col-9">
              <input
                class="form-control underLine"
                :value="pdfData.address_zip" />
            </div>
          </div>
          <div class="d-flex col-3 align-items-center">
            <div class="col-3 pb-1">
              <span class="fw-bold">Pais </span>
            </div>
            <div class="col-9">
              <input
                class="form-control underLine"
                :value="pdfData.address_country" />
            </div>
          </div>
          <div class="d-flex col-5 align-items-center">
            <div class="col-3 pb-1">
              <span class="fw-bold">Estado </span>
            </div>
            <div class="col-9">
              <input
                class="form-control underLine"
                :value="pdfData.address_state" />
            </div>
          </div>
        </div>
        <div class="row justify-content-between mb-4 pe-5">
          <div class="d-flex col-5 align-items-center">
            <div class="col-4 pb-1">
              <span class="fw-bold">Delegación </span>
            </div>
            <div class="col-8">
              <input
                class="form-control underLine"
                :value="pdfData.address_county" />
            </div>
          </div>
          <div class="d-flex col-7 align-items-center">
            <div class="col-2 pb-1">
              <span class="fw-bold">Colonia </span>
            </div>
            <div class="col-10">
              <input
                class="form-control underLine"
                :value="pdfData.address_suburb" />
            </div>
          </div>
        </div>
        <div class="row justify-content-between mb-4 pe-5">
          <div class="d-flex col-8 align-items-center">
            <div class="col-1 pb-1">
              <span class="fw-bold">Calle </span>
            </div>
            <div class="col-11">
              <input
                class="form-control underLine"
                :value="pdfData.address_street" />
            </div>
          </div>
          <div class="d-flex col align-items-center">
            <div class="col-7 pb-1">
              <span class="fw-bold">No. Ext. </span>
            </div>
            <div class="col-5">
              <input
                class="form-control underLine text-center"
                :value="pdfData.address_ext_num" />
            </div>
          </div>
          <div class="d-flex col align-items-center">
            <div class="col-7 pb-1">
              <span class="fw-bold">No. Int. </span>
            </div>
            <div class="col-5">
              <input
                class="form-control underLine text-center"
                :value="pdfData.address_int_num" />
            </div>
          </div>
        </div>
        <!-- CONTACTO -->
        <div class="d-flex justify-content-center pe-5 mb-3">
          <div class="col-12 text-center text-white skyLightBanner fw-bold">
            Contacto
          </div>
        </div>
        <div class="row justify-content-between mb-4 pe-5">
          <div class="d-flex col-5 align-items-center">
            <div class="col-2 pb-1">
              <span class="fw-bold">Teléfono</span>
            </div>
            <div class="col-10">
              <input
                class="form-control underLine"
                :value="pdfData.contact_tel" />
            </div>
          </div>
          <div class="d-flex col-6 align-items-center">
            <div class="col-2 pb-1">
              <span class="fw-bold">Email 1</span>
            </div>
            <div class="col-10">
              <input
                class="form-control underLine"
                :value="pdfData.contact_email" />
            </div>
          </div>
        </div>
        <div class="row justify-content-between mb-4 pe-5">
          <div class="d-flex col-5 align-items-center">
            <div class="col-2 pb-1">
              <span class="fw-bold">Teléfono</span>
            </div>
            <div class="col-10">
              <input
                class="form-control underLine"
                :value="pdfData.contact_tel_partner" />
            </div>
          </div>
          <div class="d-flex col-6 align-items-center">
            <div class="col-2 pb-1">
              <span class="fw-bold">Email 2</span>
            </div>
            <div class="col-10">
              <input
                class="form-control underLine"
                :value="pdfData.contact_email_partner" />
            </div>
          </div>
        </div>
        <div class="row justify-content-between mb-5 pb-5 pe-5">
          <div class="d-flex align-items-center">
            <div class="col-5 pb-1">
              <span class="fw-bold">Medio por el que se entero de CITMER </span>
            </div>
            <div class="col-7">
              <input
                class="form-control underLine"
                :value="pdfData.contact_type" />
            </div>
          </div>
        </div>
        <!-- FIRMAS -->
        <div class="row justify-content-evenly my-5 pt-4 pb-3 pe-5">
          <div class="d-flex col-4 align-items-center">
            <input
              class="form-control fw-bold signature"
              value="Firma Paciente" />
          </div>
          <div class="d-flex col-4 align-items-center">
            <input
              class="form-control fw-bold signature"
              value="Firma Pareja" />
          </div>
        </div>
      </div>
    </VueHtml2pdf>
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-plusplus */

import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "PatientInfoPdf",
  data() {
    return { date: new Date(), pdfData: {} };
  },
  props: {
    idPDF: {
      type: Number,
      required: false,
    },
  },
  components: {
    VueHtml2pdf,
  },
  watch: {
    idPDF(newID) {
      this.$api.patient.getPDF(newID).then((res) => {
        this.pdfData = res.data;
      });
    },
  },
  mounted() {
    if (this.idPDF) {
      this.$api.patient.getPDF(this.idPDF).then((res) => {
        this.pdfData = res.data;
      });
    }
  },
  updated() {
    if (this.idPDF) {
      this.$refs.html2Pdf.generatePdf();
    }
  },
  computed: {
    dynamicOcupationPatient() {
      if (this.pdfData.occupation_name === "Otro") {
        return `Otro - ${this.pdfData.occupation_other}`;
      }
      return this.pdfData.occupation_name;
    },
    dynamicOcupationCouple() {
      if (this.pdfData.partner_occupation_name === "Otro") {
        return `Otro - ${this.pdfData.partner_occupation_other}`;
      }
      return this.pdfData.partner_occupation_name;
    },
    formatDate() {
      const dateFormated = this.$moment(new Date())
        .format("dddd, DD [de] MMMM [de] YYYY hh:mm A")
        .split(" ");
      const f = dateFormated[0][0].toUpperCase();
      const s = dateFormated[0].slice(1);
      dateFormated[0] = f + s;
      return dateFormated.join(" ");
    },
    fileName() {
      const baseTitle = "ReporteFiliación_";
      const numTitle = this.pdfData.nec
        ? this.pdfData.nec
        : this.pdfData.filiation_num;
      return baseTitle + numTitle;
    },
  },
  methods: {
    formatSex(sex) {
      switch (sex) {
        case "male":
          return "M";
        case "female":
          return "F";
        default:
          return "";
      }
    },
    formatOccupation(occupation) {
      switch (occupation) {
        case "employee":
          return "Empleado";
        default:
          return "";
      }
    },
    formatID(id) {
      switch (id) {
        case "ine":
          return "INE";
        case "passport":
          return "Pasaporte";
        case "driver_license":
          return "Licencia de conducir";
        case "present_on_clinic":
          return "Se presenta en clinica";
        case "not_apply":
          return "No aplica";
        default:
          return "";
      }
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(1);
          }
        });
    },
  },
};
</script>
