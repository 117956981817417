<template>
  <div class="tablePatients">
    <b-pagination
      v-model="pageLocal"
      :total-rows="total"
      :per-page="limit"
      @change="$emit('changePage', $event)"
      align="right"
      aria-controls="my-table"></b-pagination>
    <b-table
      :items="patients"
      empty-text="No hay resultados"
      show-empty
      :fields="fieldsTable"
      :table-class="'patientsHeadClass'"
      responsive
      striped
      borderless>
      <template v-slot:cell(medic)="row">
        <p
          class="mb-0 fw-bold"
          :title="row.item.medic_name"
          :style="`color: ${getAgendaColor(
            row.item.list_services
          )} !important`">
          {{ row.item.medic_initials }}
        </p>
      </template>
      <template v-slot:cell(created_by_username)="row">
        <p class="mb-0" :title="row.item.created_by">
          {{ row.item.created_by_username }}
        </p>
      </template>
      <template v-slot:cell(status)="row">
        <div class="statusPoint mt-1" :class="colorStatus(row.item)"></div>
      </template>
      <template v-slot:cell(patient_type)="row">
        <font-awesome-icon
          class="tableSizeIconIdUser"
          :title="titleByType(row.item.patient_type)"
          :class="colorIconByType(row.item.patient_type)"
          :icon="['fa', 'hospital-user']" />
      </template>
      <!-- NEXT APPOINTMENT -->
      <template v-slot:cell(next_appointment)="row">
        <span>
          {{ convertNextDate(row.item) }}
        </span>
      </template>
      <!-- NEXT APPOINTMENT / -->
      <!-- NEC -->
      <template v-slot:cell(nec)="row">
        <span class="fw-bold">{{ row.item.nec ? row.item.nec : "-" }}</span>
      </template>
      <!-- NEC / -->
      <!-- CONTACT TEL -->
      <template v-slot:cell(contact_tel)="row">
        <font-awesome-icon
          v-if="$route.name === 'filiations'"
          class="me-2 text-secondary tableSizeIconIdUser"
          :icon="['fa', 'phone-alt']" />
        <span class="text-secondary">{{ row.item.contact_tel }}</span>
      </template>
      <!-- CONTACT TEL / -->
      <!-- FILIATION NUM -->
      <template v-slot:cell(filiation_num)="row">
        <font-awesome-icon
          v-if="$route.name === 'filiations'"
          class="me-2 text-secondary tableSizeIconIdUser"
          :icon="['far', 'id-badge']" />
        <span class="text-secondary">{{ row.item.filiation_num }}</span>
      </template>
      <!-- FILIATION NUM / -->
      <!-- NAME -->
      <template v-slot:cell(name)="row">
        <font-awesome-icon
          v-if="$route.name === 'filiations'"
          class="me-2 tableSizeIconIdUser tableSizeIconIdUser__User"
          :icon="['far', 'user']" />
        <span class="text-secondary">{{ row.item.name }}</span>
      </template>
      <!-- NAME / -->
      <!-- CONFIRMATION -->
      <template v-slot:cell(confirmation)="row">
        <span
          class="d-inline-flex align-items-center"
          @click="confirmationActions(row.item)"
          :class="[
            {
              'pointer':
                row.item.confirmation === 'pending' ||
                row.item.confirmation === 'schedule' ||
                (row.item.confirmation === 'confirmed' &&
                  row.item.on_time &&
                  !includesAsesorString(row.item.list_services)),
              'not-allowed':
                row.item.confirmation === 'confirmed' &&
                (!row.item.on_time ||
                  includesAsesorString(row.item.list_services)),
            },
            confirmStatus[row.item.confirmation].class,
          ]">
          <font-awesome-icon
            class="me-3 tableSizeIcon"
            :icon="['far', 'calendar-check']" />
          <span class="mt-1">
            {{ validateConfirmation(row.item) }}
          </span>
        </span>
      </template>
      <!-- CONFIRMATION / -->
      <!-- SERVICE -->
      <template v-slot:cell(service)="row">
        <div
          class="p-relative btn"
          :class="
            !hasManyServices(row.item.list_services.length) ? 'noPointer' : ''
          "
          @click="$emit('openPreviewServices', row.item)">
          <template v-if="hasManyServices(row.item.list_services.length)">
            <span
              class="fw-bold pointer"
              :title="`Agenda: ${getAgendaName(row.item.list_services)}`"
              :style="`color: ${getAgendaColor(
                row.item.list_services
              )} !important`">
              {{ row.item.service ? row.item.service : "Sin servicio" }}
            </span>
            <span
              class="servicesBadge pointer text-center"
              v-text="row.item.list_services.length"></span>
          </template>
          <span
            v-else
            class="fw-bold noPointer"
            :title="`Agenda: ${getAgendaName(row.item.list_services)}`"
            :style="`color: ${getAgendaColor(
              row.item.list_services
            )} !important`">
            {{ row.item.service ? row.item.service : "Sin servicio" }}
          </span>
        </div>
      </template>
      <!-- SERVICE / -->
      <!-- ACTIONS -->
      <template v-slot:cell(actions)="row">
        <PointsMenu @action="iconAction" :info="row.item" />
      </template>
      <!-- ACTIONS / -->
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  confirmStatus,
  fieldsPatients,
  fieldsFiliations,
} from "@/constants/patient";
import {
  filitiationAndPatientTableActions,
  hasPermissionIcons,
} from "@/constants/permissions";

export default {
  name: "TablePatients",
  components: {
    PointsMenu: () => import("@/components/shared/PointsMenu.vue"),
  },
  data() {
    return {
      confirmStatus,
      fieldsPatients,
      fieldsFiliations,
      actionPermission: filitiationAndPatientTableActions,
      hasPermissionIcons,
      pageLocal: 1,
    };
  },
  props: {
    filiation: {
      type: Boolean,
      required: false,
      default: false,
    },
    patients: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "getUserType",
      "getUserProfile",
      "hasPermissionToViewContactTel",
    ]),
    fieldsTable() {
      let fieldsFiliationsData = this.fieldsFiliations;
      if (this.filiation) {
        if (!this.hasPermissionToViewContactTel) {
          fieldsFiliationsData = this.fieldsFiliations.filter(
            (field) => field.key !== "contact_tel"
          );
        }
        return !this.atLeastOneHasNextAppointment
          ? fieldsFiliationsData.filter((field) => field.key !== "created_by")
          : fieldsFiliationsData;
      }
      return !this.atLeastOneHasNextAppointment
        ? this.fieldsPatients.filter((field) => field.key !== "created_by")
        : this.fieldsPatients;
    },
    atLeastOneHasNextAppointment() {
      return this.patients.some((patient) => patient.next_appointment_id);
    },
  },
  methods: {
    iconAction(type, info) {
      const isEmitAction = [
        "generatePDF",
        "openPreviewInfo",
        "authCashRegister",
        "showPaymentEvidence",
      ];
      if (isEmitAction.includes(type)) {
        if (type === "generatePDF") {
          this.$emit(type, info.patient_id);
          return;
        }
        if (type === "authCashRegister") {
          this.$emit(type, info.next_appointment_id);
          return;
        }
        this.$emit(type, info);
        return;
      }
      this[type](info);
    },
    getAgendaName(list_services) {
      return list_services[0]?.agenda_name || null;
    },
    hasManyServices(list_services) {
      return list_services > 1;
    },
    getAgendaColor(list_services) {
      return list_services[0]?.agenda_color || null;
    },
    validateConfirmation(row) {
      const { confirmation, service, list_services } = row;
      if (list_services.length === 0) return "NA";
      const { agenda_name } = list_services[0];
      if (agenda_name === "ABC" && service === "Seguimiento a Px") {
        return "NA";
      }
      return this.confirmStatus[confirmation].label;
    },
    titleByType(type) {
      const titles = {
        internal: "Paciente interno",
        external: "Paciente externo",
        donor: "Donante",
      };
      return titles[type];
    },
    colorIconByType(type) {
      const colors = {
        internal: "text-success",
        external: "text-primary",
        donor: "text-danger",
      };
      return colors[type];
    },
    hasIconsPermission(icon) {
      if (Object.keys(hasPermissionIcons).includes(this.getUserType)) {
        return this.hasPermissionIcons[this.getUserType][icon];
      }
      return false;
    },
    ...mapActions(["updateAlerts", "updateViewMode"]),
    includesAsesorString(services) {
      let includesAsesorString = false;
      services.forEach((service) => {
        if (service.name.toLowerCase().includes("asesor")) {
          includesAsesorString = true;
        }
      });
      return includesAsesorString;
    },
    colorStatus(item) {
      const states = {
        paid_out: "paidWaitForAuth",
        not_payed: "notPaidCitmer",
        free_of_charge: "secondOpinionCitmer",
      };
      if (item.cash_register_confirmation) {
        return "paidCitmer";
      }
      return states[item.is_paid] || false;
    },
    hasPermissions(type) {
      const permissionByUserType = this.actionPermission[type];
      const havePermission = permissionByUserType.includes(this.getUserType);
      if (!havePermission) {
        this.updateAlerts({
          status: "warning",
          content: "Tu usuario no tiene permisos para esta acción.",
        });
      }
      return havePermission;
    },
    convertNextDate(info) {
      if (info.next_appointment_date) {
        return `${this.$moment(info.next_appointment_date).format(
          "DD/MM/YYYY"
        )} ${info.next_appointment_time}`;
      }
      return "No hay citas próximas";
    },
    getTextServiceColor(confirmation) {
      const confirmState = this.confirmStatus[confirmation];
      if (confirmState.label === "Pendiente") return "text-primary";
      return confirmState.class;
    },
    confirmationActions(patient) {
      const confirmationStatus = patient.confirmation;
      if (confirmationStatus === "schedule") {
        this.newSchedule(patient);
      } else if (this.hasPermissions("confirmation") && patient.on_time) {
        this.$emit("appointmentTriggerAction", {
          type: confirmationStatus,
          onTime: patient.on_time,
          services: patient.list_services,
          patient,
        });
      }
    },
    newSchedule(patient) {
      if (this.getUserProfile.user_type === "medic") {
        this.updateAlerts({
          status: "warning",
          content: "Tu usuario no tiene permisos para reagendar.",
        });
        return;
      }
      const confirmationStatus = patient.confirmation;
      if (confirmationStatus === "schedule") {
        if (this.hasPermissions("newAppointment")) {
          this.$emit("appointmentTriggerAction", {
            type: "newAppointment",
            patient,
          });
        }
      } else if (confirmationStatus === "pending") {
        if (this.hasPermissions("reschedule")) {
          this.$emit("appointmentTriggerAction", {
            type: "reschedule",
            patient,
          });
        }
      }
    },
    goToDetails(patient) {
      this.updateViewMode();
      if (
        patient.medic_id !== this.getUserProfile.id &&
        this.getUserProfile.user_type === "medic"
      ) {
        this.updateAlerts({
          status: "warning",
          content: "Este paciente pertenece a otro doctor.",
        });
      } else {
        this.routerPush(patient.patient_id, patient.next_appointment_id);
      }
    },
    viewPatientHistoryResume(patient) {
      this.$emit("openResumeHistoryPatient", patient.patient_id);
    },
    viewDetails(patient) {
      this.updateViewMode(true);
      this.routerPush(patient.patient_id);
    },
    routerPush(patient, appointment = null) {
      this.$router.push({
        name: "patients.detail",
        params: { id: patient, appointment_id: appointment },
      });
    },
  },
};
</script>
