<template>
  <div class="card p-5 card-citmer cardRadius">
    <Search
      :key="'tableSearchKey' + tableListKeyData"
      @searchData="getListPatients"
      :searchAction="searchActionState"
      :labelPatient="false"
      :necRequired="necRequired"
      :inputDate="true"
      :flexDirection="'flex-end'" />
    <Table
      :key="'tableListKey' + tableListKeyData"
      :patients="listPatients"
      :page="page"
      :limit="limit"
      :total="total"
      :filiation="isFiliation"
      @changePage="changePage"
      @authCashRegister="authCashRegister"
      @openResumeHistoryPatient="openResumeHistoryPatient"
      @openPreviewInfo="openPreviewInfo"
      @openPreviewServices="openPreviewServices"
      @showPaymentEvidence="openPaymentEvidence"
      @showAdvertising="showAdvertising"
      @appointmentTriggerAction="openAppointmentAction"
      @generatePDF="generatePDF" />
    <!-- CANCEL APPOINTMENT -->
    <CancelAppointment
      ref="cancelAppointment"
      :key="keyCancelappointment"
      @refreshCalendarData="searchActionState = !searchActionState"
      @resetComponent="resetComponentByKey('cancelappointment')" />
    <!-- CANCEL APPOINTMENT /-->
    <!-- CREATE EDIT EVENT -->
    <CEAppointment
      ref="ceAppointmentParent"
      :key="keyCeappointment"
      @refreshCalendarData="searchActionState = !searchActionState"
      @resetComponent="resetComponentByKey('ceappointment')" />
    <!-- CREATE EDIT EVENT /-->
    <!-- NEW FILIATION AND EVENT -->
    <CEFiliation
      :typeAction="false"
      v-if="hasPermissionToFiliationInfoEdit"
      :key="keyCefiliation"
      @refreshCalendarData="searchActionState = !searchActionState"
      @resetComponent="resetComponentByKey('cefiliation')" />
    <PreviewServicesVue :patient="patient" />
    <!-- NEW FILIATION AND EVENT /-->
    <FiliationModal
      ref="filiationModal"
      :contentComponent="componentModal"
      :key="keyFiliationComponent"
      @resetComponent="resetComponent"
      @showServices="showServices"
      @refresh="searchActionState = !searchActionState" />
    <PatientInfoPdf :key="keyPatientPDF" :idPDF="idPatientPDF" />
    <AppointmentPassPdf :key="keyAppointmentPDF" :idPDF="idAppointmentPDF" />
    <PatientHistoryResume
      :key="keyHistory"
      ref="patientHistoryResume"
      :idPatient="idPatient"
      @resetComponent="resetComponentByKey('history')" />
  </div>
</template>

<script>
import generalDateDefinition from "@/mixin/generalDateDefinition.mixin";
import { mapGetters, mapActions, mapMutations } from "vuex";
import CEFiliation from "@/components/patient/appointment/CEFiliation.vue";
import PreviewServicesVue from "@/components/patient/PreviewServices.vue";
import CEAppointment from "@/components/appointments/CEAppointment.vue";
import CancelAppointment from "@/components/appointments/CancelAppointment.vue";
import FiliationModal from "@/components/patient/filiation/modals/Main.vue";
import Search from "@/components/shared/Search.vue";
import Table from "@/components/patient/TablePatients.vue";
import PatientInfoPdf from "@/components/patient/filiation/PatientInfoPdf.vue";
import AppointmentPassPdf from "@/components/patient/filiation/AppointmentPassPdf.vue";
import PatientHistoryResume from "@/components/patient/filiation/PatientHistoryResume.vue";

export default {
  name: "List",
  components: {
    Search,
    Table,
    PatientInfoPdf,
    AppointmentPassPdf,
    CEFiliation,
    PreviewServicesVue,
    CEAppointment,
    CancelAppointment,
    FiliationModal,
    PatientHistoryResume,
  },
  mixins: [generalDateDefinition],
  props: {
    isFiliation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    "$route.name": {
      handler() {
        this.tableListKey += 1;
        this.listPatients = [];
        this.limit = 15;
        this.page = 1;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      searchActionState: true,
      componentModal: "PaymentEvidence",
      listPatients: [],
      limit: 15,
      page: 1,
      action: "",
      total: 0,
      row: {},
      idPatient: 0,
      idPatientPDF: null,
      idAppointmentPDF: null,
      keyPatientPDF: "0-pdf",
      keyAppointmentPDF: "0-pdf2",
      keyCefiliation: "cefiliation-0",
      keyCeappointment: "ceappointment-0",
      keyCancelappointment: "cancelappointment-0",
      keyHistory: "history-0",
      keyFiliationComponent: 0,
      patient: {},
      patientInfo: {},
      tableListKey: 1,
    };
  },
  computed: {
    necRequired() {
      return this.$route.name === "patients";
    },
    ...mapGetters([
      "getClinic",
      "isCacitType",
      "getModal",
      "hasPermissionToFiliationInfoEdit",
      "getUserID",
      "getUserType",
    ]),
    tableListKeyData() {
      return this.tableListKey;
    },
    patientContent() {
      return this.patient;
    },
    rowData() {
      return this.row;
    },
    actionData() {
      return this.action;
    },
  },
  methods: {
    ...mapActions([
      "updatePatientID",
      "updatePatientSelected",
      "updateAppointmentTypeAction",
      "updateAlerts",
    ]),
    ...mapMutations([
      "setSelectedPatientID",
      "setCEAppointmentState",
      "setCEAppointmentEditID",
      "setFiliationState",
      "setSelectedPatientNextAppointment",
    ]),
    changePage(page) {
      this.page = page;
      this.searchActionState = !this.searchActionState;
    },
    authCashRegister(appointmentID) {
      this.setCEAppointmentEditID(appointmentID);
      this.componentModal = "AuthAppointmentByCashRegister";
      this.getModal("generalFiliationModal").show();
    },
    newKeyByComponentName(component) {
      const keyNumber =
        parseInt(this[`key${component.capitalize()}`].split("-")[1], 10) + 1;
      this[`key${component.capitalize()}`] = `${component}-${keyNumber}`;
    },
    resetComponentByKey(componentToReset) {
      this.newKeyByComponentName(componentToReset);
    },
    showServices(id = null) {
      if (id) {
        this.$api.patient.getPatientAppointmentPass(id).then((res) => {
          this.patient = res.data;
          this.keyAppointmentPDF += 1;
          this.idAppointmentPDF = 1;
        });
      } else {
        this.keyAppointmentPDF += 1;
        this.idAppointmentPDF = 1;
      }
    },
    generatePDF(id) {
      this.keyPatientPDF += 1;
      this.idPatientPDF = id;
    },
    defineComponentModal(type, date, time) {
      if (type === "pending" || type === "reschedule") {
        this.defineDateTimeAppointment(date, time);
        return "Appointment";
      }
      this.defineDateTimeAppointment();
      return "ceAppointment";
    },
    openResumeHistoryPatient(patientID) {
      this.idPatient = patientID;
      this.$refs.patientHistoryResume.getHistory(1, patientID);
      this.getModal("patientHistoryResume").show();
    },
    openAppointmentAction(info) {
      const { type, patient, onTime, services } = info;
      this.patient = patient;
      if (type === "confirmed" && onTime) {
        let includesAsesorString = false;
        services.forEach((service) => {
          if (service.name.toLowerCase().includes("asesor")) {
            includesAsesorString = true;
          }
        });
        if (!includesAsesorString) {
          this.showServices();
        }
      }
      if (type === "confirmed") return true;
      this.setSelectedPatientNextAppointment(patient.next_appointment_id);
      this.setSelectedPatientID(patient.patient_id);
      this.componentModal = this.defineComponentModal(
        type,
        patient.next_appointment_date,
        patient.next_appointment_time
      );
      if (this.componentModal === "ceAppointment") {
        this.setCEAppointmentState("create");
        this.getModal("ceAppointment").show();
        return true;
      }
      this.updateAppointmentTypeAction(type);
      this.getModal("generalFiliationModal").show();
      return true;
    },
    openPaymentEvidence(patient) {
      this.updatePatientSelected(patient);
      this.componentModal = "PaymentEvidence";
      this.getModal("generalFiliationModal").show();
    },
    showAdvertising(patient) {
      this.updatePatientSelected(patient);
      this.componentModal = "Advertising";
      this.getModal("generalFiliationModal").show();
    },
    isHisPatientOrCreatedByHim(patient) {
      if (
        this.getUserType === "reception" ||
        this.getUserType === "manager_cacit" ||
        this.getUserType === "superadmin"
      ) {
        return true;
      }
      return this.getUserID === patient.user_id;
    },
    openPreviewInfo(patient) {
      if (!this.isHisPatientOrCreatedByHim(patient)) {
        this.updateAlerts({
          status: "warning",
          content:
            "Solo el usuario que creo la filiación puede ver la información",
        });
        return;
      }
      this.setSelectedPatientID(patient.patient_id);
      this.setFiliationState("edit");
      this.getModal("ceFiliation").show();
    },
    openPreviewServices(patient) {
      this.patient = patient;
      this.getModal("previewServices").show();
    },
    getListPatients(payload) {
      payload.clinic_id = this.getClinic;
      payload.limit = this.limit;
      payload.page = this.page;
      this.$api.patient.getPatientList(payload).then((res) => {
        this.listPatients = res.data.patients;
        this.total = res.data.total;
      });
    },
    editDate(row) {
      this.row = row;
      this.action = "editDate";
      this.getModal("filiationModal").show();
    },
    confirmation(row) {
      this.row = row;
      this.action = "confirmation";
      this.getModal("filiationModal").show();
    },
    resetComponent() {
      this.keyFiliationComponent += 1;
      this.$store.getters.getModal("generalFiliationModal").hide();
    },
  },
};
</script>
