var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tablePatients"},[_c('b-pagination',{attrs:{"total-rows":_vm.total,"per-page":_vm.limit,"align":"right","aria-controls":"my-table"},on:{"change":function($event){return _vm.$emit('changePage', $event)}},model:{value:(_vm.pageLocal),callback:function ($$v) {_vm.pageLocal=$$v},expression:"pageLocal"}}),_c('b-table',{attrs:{"items":_vm.patients,"empty-text":"No hay resultados","show-empty":"","fields":_vm.fieldsTable,"table-class":'patientsHeadClass',"responsive":"","striped":"","borderless":""},scopedSlots:_vm._u([{key:"cell(medic)",fn:function(row){return [_c('p',{staticClass:"mb-0 fw-bold",style:(("color: " + (_vm.getAgendaColor(
          row.item.list_services
        )) + " !important")),attrs:{"title":row.item.medic_name}},[_vm._v(" "+_vm._s(row.item.medic_initials)+" ")])]}},{key:"cell(created_by_username)",fn:function(row){return [_c('p',{staticClass:"mb-0",attrs:{"title":row.item.created_by}},[_vm._v(" "+_vm._s(row.item.created_by_username)+" ")])]}},{key:"cell(status)",fn:function(row){return [_c('div',{staticClass:"statusPoint mt-1",class:_vm.colorStatus(row.item)})]}},{key:"cell(patient_type)",fn:function(row){return [_c('font-awesome-icon',{staticClass:"tableSizeIconIdUser",class:_vm.colorIconByType(row.item.patient_type),attrs:{"title":_vm.titleByType(row.item.patient_type),"icon":['fa', 'hospital-user']}})]}},{key:"cell(next_appointment)",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(_vm.convertNextDate(row.item))+" ")])]}},{key:"cell(nec)",fn:function(row){return [_c('span',{staticClass:"fw-bold"},[_vm._v(_vm._s(row.item.nec ? row.item.nec : "-"))])]}},{key:"cell(contact_tel)",fn:function(row){return [(_vm.$route.name === 'filiations')?_c('font-awesome-icon',{staticClass:"me-2 text-secondary tableSizeIconIdUser",attrs:{"icon":['fa', 'phone-alt']}}):_vm._e(),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(row.item.contact_tel))])]}},{key:"cell(filiation_num)",fn:function(row){return [(_vm.$route.name === 'filiations')?_c('font-awesome-icon',{staticClass:"me-2 text-secondary tableSizeIconIdUser",attrs:{"icon":['far', 'id-badge']}}):_vm._e(),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(row.item.filiation_num))])]}},{key:"cell(name)",fn:function(row){return [(_vm.$route.name === 'filiations')?_c('font-awesome-icon',{staticClass:"me-2 tableSizeIconIdUser tableSizeIconIdUser__User",attrs:{"icon":['far', 'user']}}):_vm._e(),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(row.item.name))])]}},{key:"cell(confirmation)",fn:function(row){return [_c('span',{staticClass:"d-inline-flex align-items-center",class:[
          {
            'pointer':
              row.item.confirmation === 'pending' ||
              row.item.confirmation === 'schedule' ||
              (row.item.confirmation === 'confirmed' &&
                row.item.on_time &&
                !_vm.includesAsesorString(row.item.list_services)),
            'not-allowed':
              row.item.confirmation === 'confirmed' &&
              (!row.item.on_time ||
                _vm.includesAsesorString(row.item.list_services)),
          },
          _vm.confirmStatus[row.item.confirmation].class ],on:{"click":function($event){return _vm.confirmationActions(row.item)}}},[_c('font-awesome-icon',{staticClass:"me-3 tableSizeIcon",attrs:{"icon":['far', 'calendar-check']}}),_c('span',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.validateConfirmation(row.item))+" ")])],1)]}},{key:"cell(service)",fn:function(row){return [_c('div',{staticClass:"p-relative btn",class:!_vm.hasManyServices(row.item.list_services.length) ? 'noPointer' : '',on:{"click":function($event){return _vm.$emit('openPreviewServices', row.item)}}},[(_vm.hasManyServices(row.item.list_services.length))?[_c('span',{staticClass:"fw-bold pointer",style:(("color: " + (_vm.getAgendaColor(
              row.item.list_services
            )) + " !important")),attrs:{"title":("Agenda: " + (_vm.getAgendaName(row.item.list_services)))}},[_vm._v(" "+_vm._s(row.item.service ? row.item.service : "Sin servicio")+" ")]),_c('span',{staticClass:"servicesBadge pointer text-center",domProps:{"textContent":_vm._s(row.item.list_services.length)}})]:_c('span',{staticClass:"fw-bold noPointer",style:(("color: " + (_vm.getAgendaColor(
            row.item.list_services
          )) + " !important")),attrs:{"title":("Agenda: " + (_vm.getAgendaName(row.item.list_services)))}},[_vm._v(" "+_vm._s(row.item.service ? row.item.service : "Sin servicio")+" ")])],2)]}},{key:"cell(actions)",fn:function(row){return [_c('PointsMenu',{attrs:{"info":row.item},on:{"action":_vm.iconAction}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }