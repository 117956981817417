<template>
  <b-modal
    ref="patientHistoryResume"
    id="patientHistoryResume"
    content-class="p-4"
    size="xl"
    title="Historial del Paciente"
    empty-text="No hay datos"
    @hide="$emit('resetComponent')"
    show-empty
    hide-header
    hide-footer>
    <div class="titles mb-3">Historial del Paciente</div>
    <b-table
      :items="patientHistory"
      :fields="fieldsPatientHistoryResume"
      :table-class="'patientsHeadClass'"
      striped
      borderless>
      <template v-slot:cell(date)="row">
        <template>
          <span v-text="$moment(row.item.date).format('DD/MM/YYYY')"></span>
        </template>
      </template>
    </b-table>
    <b-pagination
      :total-rows="total"
      v-model="page"
      :per-page="limit"
      @change="getHistory"
      align="right"
      aria-controls="my-table"></b-pagination>
  </b-modal>
</template>

<script>
import { fieldsPatientHistoryResume } from "@/constants/patient";

export default {
  name: "PatientHistoryResume",
  props: {
    idPatient: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fieldsPatientHistoryResume,
      patientHistory: null,
      limit: 10,
      total: 0,
      page: 1,
    };
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "patientHistoryResume",
      ref: this.$refs.patientHistoryResume,
    });
  },
  methods: {
    getHistory(page = 1, id) {
      const idPayload = id || this.idPatient;
      const payload = {
        page,
        limit: this.limit,
        search: this.search,
      };
      this.$api.appointments
        .getHistoryPatient(idPayload, payload)
        .then((res) => {
          this.patientHistory = res.data.appointments;
          this.total = res.data.total;
        });
    },
  },
};
</script>

<style></style>
