<template>
  <b-modal
    ref="generalFiliationModal"
    id="generalFiliationModal"
    :size="sizeByUserType"
    @hide="hideModal"
    @shown="updateAppointmentInfo"
    centered
    hide-header
    hide-footer>
    <keep-alive>
      <component
        :ref="contentComponent"
        :is="contentComponent"
        :typeAction="false"
        @showServices="showServices"
        @reloadPatientList="$emit('refresh')"
        @resetComponent="$emit('resetComponent')" />
    </keep-alive>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Modal",
  components: {
    Appointment: () =>
      import("@/components/patient/filiation/modals/Appointment.vue"),
    Advertising: () =>
      import("@/components/patient/filiation/modals/Advertising.vue"),
    PaymentEvidence: () =>
      import("@/components/patient/filiation/modals/PaymentEvidence.vue"),
    CEAppointment: () => import("@/components/appointments/CEAppointment.vue"),
    AuthAppointmentByCashRegister: () =>
      import(
        "@/components/patient/filiation/modals/AuthAppointmentByCashRegister.vue"
      ),
  },
  data() {
    return {
      keyComponent: 0,
    };
  },
  props: {
    contentComponent: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getAppointmentTypeAction"]),
    isReschedule() {
      return this.getAppointmentTypeAction === "reschedule";
    },
    isPending() {
      return this.getAppointmentTypeAction === "pending";
    },
    sizeByUserType() {
      if (this.contentComponent === "Advertising") return "md";
      if (
        this.isPending ||
        this.isReschedule ||
        this.contentComponent === "AuthAppointmentByCashRegister"
      ) {
        return "md";
      }
      return "xl";
    },
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "generalFiliationModal",
      ref: this.$refs.generalFiliationModal,
    });
  },
  methods: {
    hideModal() {
      this.$emit("resetComponent");
    },
    updateAppointmentInfo() {
      const requireGetAppointmentInfo = [
        "Appointment",
        "CEAppointment",
        "PaymentEvidence",
      ];
      if (requireGetAppointmentInfo.includes(this.contentComponent)) {
        this.$refs[this.contentComponent].getAppointmentInfo();
      }
      if (this.contentComponent === "AuthAppointmentByCashRegister") {
        this.$refs[this.contentComponent].getAppointmentInfo();
      }
    },
    showServices(id) {
      this.$emit("showServices", id);
    },
  },
};
</script>
