<template lang="html">
  <div>
    <VueHtml2pdf
      :show-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1200"
      :filename="fileName"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="letter"
      pdf-orientation="portrait"
      pdf-content-width="850px"
      ref="html2Pdf">
      <div class="p-5 text-black" slot="pdf-content">
        <div class="d-flex align-items-center justify-content-center mb-5">
          <div class="col-3"></div>
          <div class="col-6 text-center">
            <h3>Pase de servicios</h3>
          </div>
          <div class="col-3 text-center">
            <img
              style="width: 5rem; height: 5rem"
              :src="require('@/assets/images/appointment_pass.svg')" />
          </div>
        </div>
        <!-- GENERALIDADES -->
        <div class="d-flex justify-content-center pe-5 mb-3">
          <div class="col-12 text-start fw-bold">Generalidades</div>
        </div>
        <div class="d-flex pe-5">
          <table class="table table-sm table-bordered text-center">
            <thead>
              <tr class="skyLightBanner text-white">
                <th scope="col">NEC</th>
                <th scope="col">Fecha Cita</th>
                <th scope="col">Sucursal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-text="patient.nec"></td>
                <td v-text="patient.next_appointment_date"></td>
                <td v-text="patient.clinic_name"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex pe-5">
          <table class="table table-sm table-bordered text-center">
            <thead>
              <tr class="skyLightBanner text-white">
                <th scope="col">Nombre del Paciente</th>
                <th scope="col">Nombre del especialista</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-text="patient.name"></td>
                <td v-text="patient.medic_name"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex pe-5">
          <table class="table table-sm table-bordered text-center">
            <thead>
              <tr class="skyLightBanner text-white">
                <th>Consulta</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Consulta Medica</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- DETALLES -->
        <div class="d-flex justify-content-center pe-5 mb-3">
          <div class="col-12 text-start fw-bold">Detalles</div>
        </div>
        <div class="d-flex justify-content-end pe-5">
          <div class="tablePdfHeader">
            <table class="table table-sm table-bordered text-center mb-0">
              <thead>
                <tr class="text-white">
                  <td class="skyLightBanner">Nombre del Paciente</td>
                  <td class="skyLightBanner">Nombre del especialista</td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div class="d-flex pe-5">
          <table class="table table-sm table-bordered text-center">
            <thead>
              <tr class="skyLightBanner text-white">
                <th scope="col">Servicio/Medicamento</th>
                <th scope="col">Solicitado</th>
                <th scope="col">Entregado</th>
                <th scope="col">Realizado</th>
                <th scope="col">Monto a Pagar</th>
                <th scope="col">Clave Personal</th>
                <th scope="col">Firma</th>
                <th scope="col">Clave Personal</th>
                <th scope="col">Remision</th>
                <th scope="col">Firma</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="amountServices < 5">
                <tr
                  v-for="(service, index) in patient.list_services"
                  :key="`service-${index}`">
                  <td v-text="service.name"></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td v-text="service.cost"></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                </tr>
                <tr
                  v-for="number in 5 - amountServices"
                  :key="`number-blank-${number}`">
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                  <td><span class="text-white">.</span></td>
                </tr>
              </template>
              <tr
                v-else
                v-for="(service, index) in patient.list_services"
                :key="`service-${index}`">
                <td v-text="service.name"></td>
                <td><span class="text-white">.</span></td>
                <td><span class="text-white">.</span></td>
                <td><span class="text-white">.</span></td>
                <td v-text="service.cost"></td>
                <td><span class="text-white">.</span></td>
                <td><span class="text-white">.</span></td>
                <td><span class="text-white">.</span></td>
                <td><span class="text-white">.</span></td>
                <td><span class="text-white">.</span></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td v-text="totalCostServices"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- FIRMAS -->
        <div class="d-flex justify-content-center pe-5 mb-3">
          <div class="col-12 text-start fw-bold">
            Validación Médico Especialista
          </div>
        </div>
        <div class="row justify-content-evenly my-5 pt-4 pb-3 pe-5">
          <div class="d-flex col-4 align-items-center">
            <input
              class="form-control fw-bold signature"
              value="Firma Médico Tratante" />
          </div>
          <div class="d-flex col-4 align-items-center">
            <input
              class="form-control fw-bold signature"
              value="Firma Enfermera" />
          </div>
        </div>
      </div>
    </VueHtml2pdf>
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-plusplus */

import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "AppointmentPassPdf",
  data() {
    return { date: new Date(), pdfData: {}, patient: {} };
  },
  props: {
    idPDF: {
      type: Number,
      required: false,
    },
  },
  components: {
    VueHtml2pdf,
  },
  watch: {
    idPDF() {
      this.patient = this.$parent.patient;
    },
  },
  mounted() {
    if (this.idPDF) {
      this.patient = this.$parent.patient;
    }
  },
  updated() {
    if (this.idPDF) {
      this.generatePdf();
    }
  },
  computed: {
    fileName() {
      const baseTitle = "ReporteFiliación_";
      const numTitle = this.pdfData.nec
        ? this.pdfData.nec
        : this.pdfData.filiation_num;
      return baseTitle + numTitle;
    },
    amountServices() {
      if (Object.entries(this.patient).length !== 0) {
        return this.patient.list_services
          ? this.patient.list_services.length
          : 0;
      }
      return 0;
    },
    totalCostServices() {
      let amount = 0;
      if (Object.entries(this.patient).length !== 0) {
        this.patient.list_services.forEach((service) => {
          amount += service.cost;
        });
      }
      return amount;
    },
  },
  methods: {
    generatePdf() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>
